import React from 'react';
import Spin from 'react-spinkit';

const Spinner: React.FC<ISpinnerProps> = ({spin}) => {
  return spin ? (
    <span style={{display: 'flex', justifyContent: 'center', marginTop: '10vw'}}>
      <Spin name="wave" color="steelblue"/>
    </span>
  ) : <span/>
};

export default Spinner;

interface ISpinnerProps {
  spin: boolean;
}