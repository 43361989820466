import React, {Component, useEffect} from 'react';
import Parameters from './Parameters';
import ReportViewer from './ReportViewer';
import {CDataParams, getReportData, getReportString, ReportData} from '../dbApi';
import Spinner from './Spinner';
import {toCSV} from '../csvUtils';
import ErrorBoundary from './ErrorBoundary';

const AppComponent: React.FC<IAppProps> = (props) => {
  const [reportLoaded, setReportLoaded] = React.useState<boolean>(false);
  const [report, setReport] = React.useState<string>('');
  const [running, setRunning] = React.useState<boolean>(true);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [data, setData] = React.useState<ReportData[]>([]);

  useEffect(() => {
    (async function () {
      try {
        const report = await getReportString();
        setReport(report)
        setReportLoaded(true);
      } catch (err: any) {
        console.log(err);
        setErrorMessage(err.message);
      } finally {
        setRunning(false);
      }
    })();
  },[]);

  const runReport = async (params: CDataParams) => {
    try {
      setData([]);
      setErrorMessage(undefined);
      setRunning(true);
      const data = await getReportData(params);
      setData(data);
    } catch (err: any) {
      console.log(err);
      setErrorMessage(err.message);
    } finally {
      setRunning(false);
    }
  };

  const exportToCSV = () => {
    toCSV(data);
  }

  if (!reportLoaded) return <Spinner spin={true}/>;

  return (
    <ErrorBoundary>
      <Parameters
        run={runReport}
        setRunning={setRunning}
        toCSV={exportToCSV}
        running={running}
      />
      {errorMessage && <h2>{errorMessage}</h2>}
      <ReportViewer
        running={running}
        data={data}
        isError={Boolean(errorMessage)}
        reportString={report}
      />
    </ErrorBoundary>
  );
};

export default AppComponent;

interface IAppProps {}