import React, {useEffect} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import {Box, Chip, Theme, useTheme} from '@mui/material';

const SelectComponent: React.FC<ISelectProps> = (props) => {
  const theme = useTheme();
  const [selectedOptions, setSelectedOptions] = React.useState<Option[]>([]);

  useEffect(() => {
    console.log(props.value);
    setSelectedOptions(props.value || []);
  }, [props.value]);

  const onChange = (event: any) => {
    const [selection,...selected] = event.target.value.reverse() as [string, Option];
    const selectedOption = props.options.find(({value}) => value === selection);
    const found = Boolean(selected.find(({value}) => value === selection));
    // if not in selected, add
    if(!found) {
      setSelectedOptions([...selected, selectedOption!]);
    } else {
    // else remove from selected
      setSelectedOptions([...selected.filter(({value}) => value !== selection)]);
    }
  };
  const handleClose = () => {
    props.onSelect(props.name, selectedOptions);
  }
  const opts = props.options || [];
  const labelId = `${props.name}-label`;
  return (
    <FormControl fullWidth={true} variant="outlined">
      <InputLabel id={labelId} htmlFor={props.name}>{props.label}</InputLabel>
      <Select
        labelId={labelId}
        id={props.name}
        multiple={true}
        fullWidth={true}
        input={<OutlinedInput label={props.label}/>}
        name={props.name}
        onChange={onChange}
        onClose={handleClose}
        value={selectedOptions}
        renderValue={(selected: Option[]) => (
          <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
            {selected.map(({label, value}) => (
              <Chip key={value} label={label}/>
            ))}
          </Box>
        )}
      >
        {opts.map(({label, value}) =>
        <MenuItem
          value={value}
          key={value}
          style={getStyles(value, selectedOptions, theme)}
        >
          {label}
        </MenuItem>)}
      </Select>
    </FormControl>
  );
};

export default SelectComponent;

function getStyles(selection: string, selectedValues : readonly Option[] = [], theme: Theme) {
  const selected = selectedValues.findIndex(({value}) => value === selection) > -1;
  return {
    fontWeight: selected ? theme.typography.fontWeightMedium: theme.typography.fontWeightRegular,
    backgroundColor: selected ? theme.palette.grey[500] : theme.palette.background.paper
  };
}

interface ISelectProps {
  options: Option[];
  value: Option[];
  name: string;
  label: string;
  onSelect(name: string, selection: ValueType): void;
}

export type Option = {
  label: string;
  value: string;
}

type OptionType = { [prop: string]: any }
type OptionsType = Array<OptionType>

type ValueType = OptionType | OptionsType | null | void