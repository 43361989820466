import React, {useEffect} from 'react';
import {Stimulsoft} from 'stimulsoft-reports-js';

let win: any = window;
const stimulsoft = win.Stimulsoft;

const VIEWER_ELEM = 'viewer';
const DS_Name = 'root';

stimulsoft.Base.StiLicense.loadFromString(process.env.REACT_APP_STIMULSOFT_KEY);

const ReportViewer: React.FC<IReportViewerProps> = (props) => {
  let dataset: Stimulsoft.System.Data.DataSet;
  let report: Stimulsoft.Report.StiReport;
  let viewer: Stimulsoft.Viewer.StiViewer;

  const loadReportData = () => {
    const options = new stimulsoft.Viewer.StiViewerOptions();
    options.appearance.fullScreenMode = false;
    options.toolbar.displayMode = stimulsoft.Viewer.StiToolbarDisplayMode.Separated;
    options.toolbar.viewMode = stimulsoft.Viewer.StiWebViewMode.Continuous;
    options.toolbar.showParametersButton = false;
    options.toolbar.showBookmarksButton = false;
    options.toolbar.showResourcesButton = false;
    options.toolbar.showOpenButton = false;
    options.toolbar.showAboutButton = false;
    viewer = new stimulsoft.Viewer.StiViewer(options, VIEWER_ELEM, false);
    report = stimulsoft.Report.StiReport.createNewReport();
    report.loadPacked(props.reportString);
    report.dictionary.databases.clear();
    dataset = new stimulsoft.System.Data.DataSet(DS_Name);
    dataset.readJson(props.data);
    report.regData(dataset.dataSetName, '', dataset);
    viewer.report = report;
    viewer.renderHtml(VIEWER_ELEM);
  };

  const showProcess = () => {
    viewer?.showProcessIndicator();
  };
  const hideProcess = () => {
    viewer?.hideProcessIndicator();
  };

  useEffect(() => {
    props.running ? showProcess() : hideProcess();
  }, [props.running]);
  useEffect(loadReportData, [props.data]);
  useEffect(hideProcess, [props.isError]);
  return (
    <div id={VIEWER_ELEM}/>
  );
};

export default ReportViewer;

interface IReportViewerProps {
  running: boolean;
  reportString: string;
  data?: any[];
  isError?: boolean;
}