import {ReportData} from './dbApi';

const header: string = 'projPhase,projectName,type,pm,ae,fundingSource,constBdgt,bidCac,projCac,projBdgt,curEstPhase,aeffFinish,designPhase,dsaStart,dsaFinish,bidStart,awardFinish,fundedYear';

export const toCSV = (data: ReportData[]) => {
  // accumulate string of csv data.
  // pre-fill header rows
  let str: string = `${header}\n`;
  data.forEach((row: ReportData) => {
    str += getCsvRow(row);
  });
  // download csv file
  downloadCsvFile(str);

};

function getCsvRow(row: ReportData) {
  let retStr: string = '';
  header.split(',').forEach((prop: string, i, headers) => {
    let val = row[prop as keyof ReportData] || '';
    retStr += `${String(val).replace(/,/g, ' ')}`;
    // should I add a trailing comma or new line?
    retStr += i !== headers.length - 1 ? ',' : '\n';
  });
  return retStr;
}

function downloadCsvFile(data: string) {
  const now = new Date();
  const elem = document.createElement('a');
  elem.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
  elem.target = '_blank';
  elem.download = `Rainbow Report Data Export-${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}-${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}.csv`;
  elem.click();
}